import { getCsrfToken } from 'utils/getCsrfToken';
import { authenticatedHttpRequest } from 'utils/http';

export type TenantData = {
  readonly sessionInfo: {
    readonly tenant: {
      readonly domain: string;
      readonly icon: {
        readonly url: URIString;
      } | null;
      readonly name: string;
      readonly id: string;
    } | null;
  };
};

const typeGuard = (data: unknown): data is TenantData => {
  return Boolean((data as TenantData)?.sessionInfo?.tenant);
};

// this loads tenant data for a given tenant if you are not logged in
// and you load via app.*
export const loadTenantDataFor = async (
  tenantName: string,
): Promise<TenantData> => {
  const token = await getCsrfToken();

  const domain = window.location.hostname.split('.').slice(1).join('.');
  const data = await authenticatedHttpRequest('/graph', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Tenant-Domain': `${tenantName}.${domain}`,
      'X-CSRF-Token': token,
    },
    body: JSON.stringify({
      query: `
        query loadTenantDataFor_Query {
          sessionInfo {
            tenant {
              id
              name
              domain
              icon {
                url
              }
            }
          }
        }
      `,
      variables: {},
    }),
  });
  const jsonData = await data.json();

  if (!typeGuard(jsonData?.data)) {
    throw new Error('Tenant does not exist');
  }

  return jsonData.data;
};

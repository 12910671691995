import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@cardboard-ui/react';
import { AuthenticationScreen } from '../AuthenticationScreen';
import * as EmailValidator from 'email-validator';
import { TenantData } from '../SignIn/utils/loadTenantData';
import { FORGOT_PASSWORD_PATH } from 'utils/routes';
import isAppDomain from 'utils/isAppDomain';

export type TenantPasswordLoginProps = {
  onSubmitLogin: (email: string, password: string) => void;
  onLookingForOtherPlatform: () => void;
  ExternalAuth: React.ElementType;
  mobileTenantName?: string;
  tenant: TenantData['sessionInfo']['tenant'];
};

export const TenantPasswordLogin = ({
  onSubmitLogin,
  onLookingForOtherPlatform,
  ExternalAuth,
  tenant,
}: TenantPasswordLoginProps) => {
  const [value, setValue] = useState('');
  const [password, setPassword] = useState('');
  const isMobileApp = isAppDomain();

  const isValidMail = EmailValidator.validate(value);

  return (
    <AuthenticationScreen>
      <Stack spacing={4}>
        <VStack>
          <Image
            src={tenant?.icon?.url}
            borderWidth="2px"
            borderRadius="md"
            borderColor="white"
            borderStyle="solid"
            alt=""
            boxSize="50px"
            objectFit="cover"
            textAlign="center"
          />
          <Heading fontWeight="bold" fontSize="2xl" mb={2} textAlign="center">
            <Trans>Sign in to {tenant?.name ?? 'Trusted Family'}</Trans>
          </Heading>
        </VStack>

        <VStack
          as="form"
          onSubmit={(event) => {
            event.preventDefault();
            onSubmitLogin(value, password);
          }}
        >
          <FormControl>
            <FormLabel fontWeight="bold">{t`E-mail`}</FormLabel>
            <Input
              value={value}
              type="email"
              onChange={(event) => {
                setValue(event.target.value);
              }}
              placeholder={t`name@your-email.com`}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight="bold">{t`Password`}</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              placeholder={t`Your password`}
            />
          </FormControl>

          <Button
            variant="solid"
            colorScheme="blue"
            type="submit"
            w="full"
            mt={2}
            isDisabled={!isValidMail || !password.length}
          >{t`Sign in with email`}</Button>
        </VStack>

        <VStack alignItems="flex-start" fontSize="xs" color="gray.500">
          <HStack gap={1} wrap="wrap">
            <Text>{t`Forgot your password?`}</Text>
            <Link
              to={
                isAppDomain() && tenant?.domain
                  ? `${FORGOT_PASSWORD_PATH}?${new URLSearchParams({
                      tenant: tenant.domain,
                    })}`
                  : FORGOT_PASSWORD_PATH
              }
              style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >{t`Reset password here`}</Link>
          </HStack>

          {isMobileApp && (
            <HStack gap={1} wrap="wrap">
              <Text>{t`Looking for another platform?`}</Text>
              <Link
                to="#"
                onClick={(event) => {
                  event.preventDefault();
                  onLookingForOtherPlatform();
                }}
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >{t`Find your platform`}</Link>
            </HStack>
          )}
          <Box position="relative" paddingY={4} w="full">
            <Divider />
            <AbsoluteCenter
              px={4}
              bg={useColorModeValue('white', 'gray.700')}
              color="gray.500"
            >{t`OR`}</AbsoluteCenter>
          </Box>
          <ExternalAuth />
        </VStack>
      </Stack>
    </AuthenticationScreen>
  );
};

import { Spinner, useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { SIGN_IN_PATH } from 'utils/routes';
import { useSession } from 'utils/sessionProvider';
import {
  TenantPasswordLogin,
  TenantPasswordLoginProps,
} from '../../redesigned/TenantPasswordLogin';
import { loadTenantDataFor, TenantData } from '../utils/loadTenantData';
import { useAuthLogic } from '../utils/SignInLogic';
import { GoogleButton } from '..';

// Screen logic differed between browser and app
// Hence the split, while keeping them close to each other

export const SigningInWithPasswordScreenBrowser = () => {
  const { onSubmit, setScreen } = useAuthLogic();
  const navigate = useNavigate();
  const toast = useToast();
  const { tenant: _tenant } = useSession();
  const tenant: TenantPasswordLoginProps['tenant'] = {
    domain: _tenant?.domain ?? '',
    name: _tenant?.name ?? '',
    icon: { url: _tenant?.icon?.url ?? '' },
    id: _tenant?.id ?? '',
  };

  return (
    <TenantPasswordLogin
      onSubmitLogin={(login, password) => {
        onSubmit({ login, password }, (success) => {
          if (!success) {
            toast({
              title: t`Could not sign in`,
              description: t`Invalid credentials`,
              status: 'error',
              isClosable: true,
              duration: 5000,
            });
          }
        });
      }}
      onLookingForOtherPlatform={() => {
        setScreen({ screen: 'find-platform', data: null });
      }}
      ExternalAuth={GoogleButton}
      tenant={tenant}
    />
  );
};

export const SigningInWithPasswordScreenApp = () => {
  const {
    onSubmit,
    setScreen,
    screen: { data },
  } = useAuthLogic();
  const toast = useToast();
  const [tenantDetails, setTenantDetails] =
    useState<TenantData['sessionInfo']['tenant']>(null);

  const handleNoTenant = () => {
    toast({
      title: t`Invalid tenant`,
      description: t`The tenant does not exist`,
      status: 'error',
      isClosable: true,
      duration: 5000,
    });
    setScreen({ screen: 'find-platform', data: null });
  };

  useEffect(() => {
    // this should only happen for mobile app
    // desktop version already has the tenant in the url
    if (!data?.tenantName) {
      return;
    }
    loadTenantDataFor(data.tenantName).then((data) => {
      if (data.sessionInfo.tenant) {
        setTenantDetails(data.sessionInfo.tenant);
      } else {
        handleNoTenant();
      }
    }, handleNoTenant);
  }, []);

  if (!data?.tenantName) {
    return <Navigate to={SIGN_IN_PATH} />;
  }

  if (!tenantDetails) {
    return <Spinner />;
  }

  return (
    <TenantPasswordLogin
      onSubmitLogin={(login, password) => {
        onSubmit(
          { login, password, tenant_id: tenantDetails.id },
          (success) => {
            if (success) {
              setScreen({ screen: 'pick-tenant', data: null });
            } else {
              toast({
                title: t`Could not sign in`,
                description: t`Invalid credentials`,
                status: 'error',
                isClosable: true,
                duration: 5000,
              });
            }
          },
        );
      }}
      onLookingForOtherPlatform={() => {
        setScreen({ screen: 'find-platform', data: null });
      }}
      ExternalAuth={GoogleButton}
      tenant={tenantDetails}
    />
  );
};

// eslint-disable-next-line no-restricted-imports
import { format as dateFormat } from 'date-fns';
import { AvailableLocale, DEFAULT_LOCALE } from '.';
import { dateFnsLocale } from './dateFnsLocale';
import { parseApplicationDate } from './parseApplicationDate';

export type FormatFn = (
  date: Parameters<typeof parseApplicationDate>[0],
  format: string,
  options?: FormatOptions,
) => string;

export const buildFormat =
  (locale: AvailableLocale): FormatFn =>
  (date, _format, options) => {
    const format = options
      ? formatWithOptions(locale, _format, options)
      : _format;

    return dateFormat(parseApplicationDate(date), format, {
      locale: dateFnsLocale(locale || 'other'),
    });
  };

type FormatOptions = {
  withoutYear?: boolean;
};

const formatWithOptions = (
  locale: string,
  format: string,
  options: FormatOptions,
) => {
  if (options.withoutYear) {
    if (format === 'PPP') {
      return (
        longFormatWithoutYear[locale] || longFormatWithoutYear[DEFAULT_LOCALE]
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Can not apply withoutYear option to '${format}' format`);
    }
  }

  return format;
};

const longFormatWithoutYear: { [locale: string]: string } = {
  en: 'MMMM do',
  'en-US': 'MMMM do',
  'en-GB': 'd MMMM',
  fr: 'd MMMM',
  ar: 'do MMMM',
  de: 'do MMMM',
  nl: 'd MMMM',
  pt: "d 'de' MMMM",
  'pt-BR': "d 'de' MMMM",
  es: "d 'de' MMMM",
  it: 'd MMMM',
};

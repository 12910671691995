import React from 'react';
import { useAuthLogic } from '../utils/SignInLogic';
import { useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { FindPlatform } from '../../redesigned/FindPlatform';
import { loadTenantDataFor } from '../utils/loadTenantData';
import isAppDomain from 'utils/isAppDomain';

const isMobileApp = isAppDomain();

export const FindPlatformScreen = () => {
  const { setScreen } = useAuthLogic();

  const toast = useToast();

  const invalid = () => {
    toast({
      title: t`Invalid tenant`,
      description: t`The tenant does not exist`,
      status: 'error',
      isClosable: true,
      duration: 5000,
    });
  };

  return (
    <FindPlatform
      onConfirmPlatform={(name) => {
        loadTenantDataFor(name).then((entry) => {
          if (entry.sessionInfo.tenant) {
            if (isMobileApp) {
              setScreen({
                screen: 'login-with-password',
                data: { tenantName: name },
              });
            } else {
              //   const domain = isDevMode()
              //     ? 'https://_tenant_.lvh.me'
              //     : 'https://_tenant_.trustedfamily.com';
              //   const withTenant = domain.replace('_tenant_', name);
              //   const withDirectLogin = `${withTenant}?screen=login-with-password`;
              // eslint-disable-next-line no-restricted-properties
              //   window.location.assign(withDirectLogin);
            }
          } else {
            invalid();
          }
        }, invalid);
      }}
      onForgotPlatform={() =>
        setScreen({ screen: 'forgot-platform', data: null })
      }
    />
  );
};

import React, { useState } from 'react';
import { t } from '@lingui/macro';
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  VStack,
} from '@cardboard-ui/react';
import { AuthenticationScreen } from '../AuthenticationScreen';
import { TrustedFamilyLogo } from './assets/Logo';

type FindTenantByMailProps = {
  onConfirmMail: (email: string) => void;
  ExternalAuth: React.ElementType;
};

export const FindTenantByMail = ({
  onConfirmMail,
  ExternalAuth,
}: FindTenantByMailProps) => {
  const [value, setValue] = useState('');

  return (
    <AuthenticationScreen>
      <Stack spacing={4}>
        <VStack>
          <TrustedFamilyLogo />
          <Heading
            fontWeight="bold"
            fontSize="2xl"
          >{t`Let's find your platform`}</Heading>
        </VStack>
        <Input
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          placeholder={t`name@your-email.com`}
          type="email"
        />

        <Button
          variant="solid"
          colorScheme="blue"
          onClick={() => {
            onConfirmMail(value);
          }}
        >{t`Continue with email`}</Button>

        <Box position="relative" paddingY={4} w="full">
          <Divider />
          <AbsoluteCenter
            px={4}
            bg={useColorModeValue('white', 'gray.700')}
            color="gray.500"
          >{t`OR`}</AbsoluteCenter>
        </Box>
        <ExternalAuth />
      </Stack>
    </AuthenticationScreen>
  );
};

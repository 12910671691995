import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { authenticatedHttpRequest } from 'utils/http';
import { useAuthenticatedSession, useSession } from 'utils/sessionProvider';
import { SIGN_OUT_PATH, TOKEN_PARAM } from 'utils/routes';
import useSafeParam from 'utils/routes/useSafeParam';
import SignInFromSkeleton from './SignInFromSkeleton';
import { t, Trans } from '@lingui/macro';
import { AuthenticationScreen } from './AuthenticationScreen';
import { AuthenticationScreenHeading } from './AuthenticationScreenHeading';
import {
  Avatar,
  Box,
  Button,
  forwardRef,
  Link,
  SimpleGrid,
  Stack,
  VStack,
} from '@cardboard-ui/react';
import { Card } from 'components/cards/Card';
import AlreadySignedIn from './AlreadySignedIn';
import { useSignOut } from './SignOut';
import { Layout, useActiveLayout } from 'utils/LayoutProvider';
import { provider_SessionMemberInfo_Query$data } from 'utils/sessionProvider/__generated__/provider_SessionMemberInfo_Query.graphql';

export type SessionMember = NonNullable<
  provider_SessionMemberInfo_Query$data['sessionInfo']['member']
>;

export const SignInFromSwitch = forwardRef<{}, 'div'>((_, ref) => {
  const token = useSafeParam(TOKEN_PARAM);
  const { isAuthenticated } = useSession();
  const [initiallyAuthenticated, setInitiallyAuthenticated] = useState<
    undefined | boolean
  >(undefined);

  useEffect(() => {
    setInitiallyAuthenticated(isAuthenticated);
  }, []);

  if (initiallyAuthenticated === undefined) {
    // While we are waiting for the initial state,
    //   we should not take any real action.
    return <AlreadySignedIn ref={ref} returnToPath={false} />;
  }

  if (initiallyAuthenticated) {
    return <SignInFromSwitchAuthenticated ref={ref} token={token} />;
  } else {
    return <SignInFromSwitchNotAuthenticated ref={ref} token={token} />;
  }
});

export default SignInFromSwitch;

interface SignInFromSwitchProps {
  token: string;
}

// We can be sure we did not start out as authenticated,
// So we can immediately start with authentication
const SignInFromSwitchNotAuthenticated = forwardRef<
  SignInFromSwitchProps,
  'div'
>(({ token }, ref) => {
  const { authenticate, isAuthenticated } = useSession();
  const [invalidToken, setInvalidToken] = useState(false);

  useEffect(() => {
    httpSwitchAuthRequest(token).then(async (res) => {
      if (res.status == 200) {
        const data = await res.json();
        authenticate();
      } else {
        setInvalidToken(true);
      }
    });
  }, []);

  // We started out not authenticated, so now we are sure we
  //   have authenticated the correct account. Let's go home.
  if (isAuthenticated) {
    return <AlreadySignedIn ref={ref} />;
  }

  // Else we run the spinner or invalid token state
  return <SignInFromSkeleton ref={ref} invalidToken={invalidToken} />;
});

// We have an existing account, it is possible that the token
//   & existing account are referring the same account
// Or we will need to let the user choose.
const SignInFromSwitchAuthenticated = forwardRef<SignInFromSwitchProps, 'div'>(
  ({ token }, ref) => {
    const { member: currentMember, authenticate } = useAuthenticatedSession();
    const [newMember, setNewMember] = useState<undefined | SessionMember>(
      undefined,
    );
    const [invalidToken, setInvalidToken] = useState(false);
    const [selectedCurrentSession, setSelectedCurrentSession] = useState(false);
    const [selectedNewSession, setSelectedNewSession] = useState(false);
    const { startSignOut } = useSignOut({ autoStart: false });
    const isApp = window.location.hostname.startsWith('app.');

    useEffect(() => {
      httpSwitchAuthInfoRequest(token).then(async (res) => {
        if (res.status == 200) {
          const data = await res.json();
          if (data.valid) {
            setNewMember(data.member);
          } else {
            setInvalidToken(true);
          }
        } else {
          setInvalidToken(true);
        }
      });
    }, [token]);

    useEffect(() => {
      if (isApp && newMember?.id !== currentMember.id) {
        setSelectedNewSession(true);
      }
    }, [isApp, newMember]);

    useEffect(() => {
      if (selectedNewSession) {
        startSignOut(() => {
          httpSwitchAuthRequest(token).then(async (res) => {
            if (res.status == 200) {
              const data = await res.json();
              authenticate();
            } else {
              setInvalidToken(true);
            }
          });
        });
      }
    }, [selectedNewSession]);

    if (newMember?.id === currentMember.id) {
      // Great, the token is for the current account,
      //   we should just redirect the user home.
      return <AlreadySignedIn ref={ref} />;
    }

    if (invalidToken) {
      return <SignInFromSkeleton ref={ref} invalidToken={invalidToken} />;
    }

    if (!newMember) {
      // If we are still fetching the token info, pretend we are signing in
      //   but be sure to not redirect
      return <AlreadySignedIn ref={ref} returnToPath={false} />;
    }

    if (selectedCurrentSession) {
      // We want to keep the current session. No problem, let's go home.
      return <AlreadySignedIn ref={ref} />;
    }

    if (selectedNewSession) {
      // While we are doing the work to sign in to the new session
      //   lets show a loader
      // In principal the `newMember?.id === currentMember.id` condition
      //   will automatically take over in a short while.
      return <AlreadySignedIn ref={ref} returnToPath={false} />;
    }

    return (
      <ChooseSessionScreen
        newMember={newMember}
        currentMember={currentMember}
        onSelectCurrentSession={() => setSelectedCurrentSession(true)}
        onSelectNewSession={() => setSelectedNewSession(true)}
      />
    );
  },
);

export const httpSwitchAuthRequest = (token: string) =>
  authenticatedHttpRequest('/auth/switch-auth', {
    method: 'POST',
    body: JSON.stringify({
      key: token,
    }),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

const httpSwitchAuthInfoRequest = (token: string) =>
  authenticatedHttpRequest('/auth/switch-auth-info', {
    method: 'POST',
    body: JSON.stringify({
      key: token,
    }),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

interface ChooseSessionScreenProps {
  newMember: SessionMember;
  currentMember: SessionMember;
  onSelectCurrentSession: () => void;
  onSelectNewSession: () => void;
}

export const ChooseSessionScreen = forwardRef<ChooseSessionScreenProps, 'div'>(
  (
    { newMember, currentMember, onSelectCurrentSession, onSelectNewSession },
    ref,
  ) => {
    const activeLayout = useActiveLayout();
    return (
      <AuthenticationScreen ref={ref}>
        <Stack>
          <AuthenticationScreenHeading>
            {t`You are already signed in`}
          </AuthenticationScreenHeading>
          <Box>
            <Trans>
              We see that you are already signed in with another account. You
              can choose to continue with your current session or switch to the
              other account.
            </Trans>
          </Box>
          <SimpleGrid
            columns={activeLayout == Layout.DESKTOP ? 2 : 1}
            spacing={4}
          >
            <StayOnCurrentSession
              member={currentMember}
              onClick={onSelectCurrentSession}
            />
            <SwitchToNewSession
              member={newMember}
              onClick={onSelectNewSession}
            />
          </SimpleGrid>
          <Box display="flex" justifyContent="end" pt={4}>
            <Button as={Link} to={SIGN_OUT_PATH}>
              {t`Sign out`}
            </Button>
          </Box>
        </Stack>
      </AuthenticationScreen>
    );
  },
);

interface SessionProps {
  member: SessionMember;
  onClick: () => void;
}

const StayOnCurrentSession: FC<SessionProps> = (props) => {
  return (
    <NewSessionCard info={t`Your current session`} {...props}>
      {t`Continue`}
    </NewSessionCard>
  );
};

const SwitchToNewSession: FC<SessionProps> = (props) => {
  return (
    <NewSessionCard info={t`You can switch to`} {...props}>
      {t`Switch`}
    </NewSessionCard>
  );
};

interface NewSessionCardProps extends SessionProps {
  info: string;
}

const NewSessionCard: FC<PropsWithChildren<NewSessionCardProps>> = ({
  info,
  member,
  children,
  onClick,
}) => {
  return (
    <Card p={4} w="100%" onClick={onClick}>
      <VStack align="center" textAlign="center" spacing={4} h="100%">
        <Box>{info}</Box>
        <Avatar size="lg" name={member.name} src={member.avatar?.url} />
        <Box flexGrow={1}>
          <strong>{member.name}</strong>
          <br />
          {member.email}
        </Box>
        <Button as={Box} w="100%" alignSelf="flex-end">
          {children}
        </Button>
      </VStack>
    </Card>
  );
};

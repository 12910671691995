import React from 'react';
import { TenantPasswordlessLogin } from '../../redesigned/TenantPasswordlessLogin';
import { useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { GoogleButton } from '..';
import { useAuthLogic } from '../utils/SignInLogic';
import isAppDomain from 'utils/isAppDomain';

const isMobileApp = isAppDomain();

const SignInTenant = () => {
  const { setScreen, onSubmit } = useAuthLogic();
  const toast = useToast();

  return (
    <TenantPasswordlessLogin
      onClickSignInViaPassword={() => {
        if (isMobileApp) {
          setScreen({ screen: 'find-platform', data: null });
        } else {
          setScreen({ screen: 'login-with-password', data: null });
        }
      }}
      onLoginWithOnlyMail={(login) => {
        onSubmit({ login }, (_, token, statusCode) => {
          if (token) {
            setScreen({ screen: 'with-email-pin', data: { token } });
          }

          if (statusCode !== null) {
            switch (statusCode) {
              case 'INVALID_EMAIL':
                toast({
                  title: t`Invalid email address`,
                  description: t`Please enter a valid email address.`,
                  status: 'error',
                  isClosable: true,
                  duration: 5000,
                });
                break;
              case 'RATE_LIMIT_EXCEEDED':
                toast({
                  title: t`Rate limit exceeded`,
                  description: t`We already handled a sign-in request for this email. Please try again in a few minutes`,
                  status: 'error',
                  isClosable: true,
                  duration: 5000,
                });
                break;
              default:
                toast({
                  title: t`Request failed`,
                  description: t`An error occurred while trying to sign in`,
                  status: 'error',
                  isClosable: true,
                  duration: 5000,
                });
                break;
            }
          }
        });
      }}
      ExternalAuth={GoogleButton}
    />
  );
};

export default SignInTenant;

import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Stack,
  useColorModeValue,
  useToast,
  VStack,
} from '@cardboard-ui/react';
import { AuthenticationScreen } from '../AuthenticationScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TrustedFamilyLogo } from './assets/Logo';
import * as EmailValidator from 'email-validator';
import { useSession } from 'utils/sessionProvider';

type TenantPasswordlessLoginProps = {
  onClickSignInViaPassword: () => void;
  onLoginWithOnlyMail: (email: string) => void;
  ExternalAuth: React.ComponentType;
};

export const TenantPasswordlessLogin = ({
  onClickSignInViaPassword,
  onLoginWithOnlyMail,
  ExternalAuth,
}: TenantPasswordlessLoginProps) => {
  const [value, setValue] = useState('');
  const toast = useToast();
  const isValid = EmailValidator.validate(value);
  const { tenant } = useSession();

  return (
    <AuthenticationScreen>
      <Stack spacing={4}>
        <VStack>
          {tenant?.icon?.url ? (
            <Image
              src={tenant.icon.url}
              borderWidth="2px"
              borderRadius="md"
              borderColor="white"
              borderStyle="solid"
              boxSize="50px"
              objectFit="cover"
              textAlign="center"
            />
          ) : (
            <TrustedFamilyLogo />
          )}
          <Heading fontWeight="bold" fontSize="2xl" textAlign="center">
            <Trans>Sign in to {tenant?.name ?? 'Trusted Family'}</Trans>
          </Heading>
        </VStack>
        <form
          onSubmit={(event) => {
            event.preventDefault();

            if (isValid) {
              onLoginWithOnlyMail(value);
            } else {
              toast({
                title: t`Invalid email`,
                description: t`Please enter a valid email address.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          }}
        >
          <Input
            value={value}
            type="email"
            onChange={(event) => {
              setValue(event.target.value);
            }}
            placeholder={t`name@your-email.com`}
          />

          <Button
            variant="solid"
            colorScheme="blue"
            isDisabled={!isValid}
            type="submit"
            w="full"
            mt={2}
          >{t`Sign in with email`}</Button>
        </form>

        <VStack>
          <HStack
            bg={useColorModeValue('gray.100', 'gray.700')}
            borderRadius="md"
            p={4}
            spacing={2}
            alignItems="flex-start"
            w="full"
          >
            <FontAwesomeIcon
              icon={light('sparkles')}
              style={{ marginTop: '4px' }}
            />
            <VStack
              alignItems="flex-start"
              color={useColorModeValue('gray.600', 'gray.400')}
              gap={0}
              fontSize="sm"
            >
              <Trans>
                We'll email you an authentication code for a password-free
                sign-in.
              </Trans>
              <Box>
                <Trans>
                  Or you can{' '}
                  <Link
                    to="#"
                    onClick={(event) => {
                      onClickSignInViaPassword();
                      event.preventDefault();
                    }}
                    fontWeight="bold"
                    textDecoration="underline"
                  >
                    sign in with password instead
                  </Link>
                </Trans>
              </Box>
            </VStack>
          </HStack>
          <Box position="relative" paddingY={4} w="full">
            <Divider />
            <AbsoluteCenter
              px={4}
              bg={useColorModeValue('white', 'gray.700')}
              color="gray.500"
              fontSize="sm"
            >{t`OR`}</AbsoluteCenter>
          </Box>
          <ExternalAuth />
        </VStack>
      </Stack>
    </AuthenticationScreen>
  );
};

import { Button, Link, useInterval, VStack } from '@cardboard-ui/react';
import loadable, { lazy } from '@loadable/component';
import React, { FC, useCallback, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Layout, useActiveLayout } from 'utils/LayoutProvider';
import { RequireAuthentication } from 'utils/RequireAuthentication';
import {
  asterisk,
  CALENDAR_PATH,
  CANONICAL_ITEM_PARAM,
  CANONICAL_PREFIXES,
  CHANGE_PASSWORD_PATH,
  CHAT_PATH,
  defaultTenantLandingPath,
  FAVOURITES_PATH,
  FORGOT_PASSWORD_PATH,
  HOME_PATH,
  MEMBER_DIRECTORY_PATH,
  MEMBER_PATH,
  MORE_PATH,
  NOTIFICATIONS_PATH,
  pathParam,
  PRIVATE_VAULT_PATH,
  RESET_PASSWORD_PATH,
  SETUP_COMPLETE_PATH,
  SETUP_TENANT_CONFIRMATION_PATH,
  SETUP_TWO_FACTOR_PATH,
  SIGN_IN_PATH,
  SIGN_IN_TWO_FACTOR_PATH,
  SIGN_IN_WITH_EMAIL_TOKEN_PATH,
  SIGN_IN_WITH_GLOBAL_TOKEN_ACCOUNT_SELECT_PATH,
  SIGN_IN_WITH_GLOBAL_TOKEN_PATH,
  SIGN_IN_WITH_SWITCH_TOKEN_PATH,
  SIGN_UP_COMPLETE_PATH,
  SIGN_UP_CONFIRMATION_PATH,
  SIGN_UP_PATH,
  SPACES_PATH,
  START_PATH,
  STATISTICS_PATH,
  TERMS_AND_CONDITIONS_PATH,
  TODO_PATH,
  VAULTS_PATH,
  VERIFY_EMAIL_PATH,
} from 'utils/routes';
import LoadingScreen from '../../screens/Loading';

import { t } from '@lingui/macro';
import { FolderViewModeProvider } from 'components/FolderViewer/FolderViewModeProvider';
import { ScreenWithSingleSection } from 'layouts/ScreenWithSingleSection';
import { SidecarProvider } from 'providers/SidecarProvider';
import { UnreadChatMessagesProvider } from 'providers/UnreadChatMessagesProvider';
import { UnreadNotificationsProvider } from 'providers/UnreadNotificationsProvider';
import { useSession } from 'utils/sessionProvider';
import CanonicalRedirect from './components/CanonicalRedirect';
import { ItemViewerNavigationProvider } from './screens/ItemViewer/ItemViewerNavigationProvider';
import NoTenant from './screens/NoTenant';
import { NotFoundContent } from './screens/NotFound';
import { authenticatedHttpRequest } from 'utils/http';
import useFetchKey from 'hooks/useFetchKey';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { CalendarViewProvider } from 'screens/Calendar/providers';
const PDFTronPreloader = lazy(
  () =>
    import(
      './screens/ItemViewer/FileRenderer/PDFTronFileRenderer/PDFTronPreloader'
    ),
);
import SignInFromGlobalEmailToken from './screens/authentication/SignInFromGlobalEmailToken';
import SignInWithTokenAccountSelect from './screens/authentication/SignInWithTokenAccountSelect';
import SignInFromEmail from './screens/authentication/SignInFromEmail';
import SignInFromSwitch from './screens/authentication/SignInFromSwitch';
import { allowNonTenantAuthentication } from 'apps/TenantApp/screens/authentication/SignIn/legacy';
import { useFlag } from '@unleash/proxy-client-react';

const loadingPageFallback = {
  fallback: <LoadingScreen />,
};

const DesktopLayout = lazy(() => import('./components/Layout.Desktop'));
const MobileLayout = lazy(() => import('./components/Layout.Mobile'));

const Home = loadable(() => import('./screens/Home'), loadingPageFallback);
const Start = loadable(() => import('./screens/Start'), loadingPageFallback);
const More = loadable(() => import('./screens/More'), loadingPageFallback);

const ForgotPassword = loadable(
  () => import('apps/TenantApp/screens/authentication/ForgotPassword'),
  loadingPageFallback,
);
const ResetPassword = loadable(
  () => import('apps/TenantApp/screens/authentication/ResetPassword'),
  loadingPageFallback,
);
const SignUp = loadable(
  () => import('apps/TenantApp/screens/setup/SignUp'),
  loadingPageFallback,
);
const VerifyEmail = loadable(
  () => import('apps/TenantApp/screens/VerifyEmail'),
  loadingPageFallback,
);
const SetupTenant = loadable(
  () => import('apps/TenantApp/screens/setup/SetupTenant'),
  loadingPageFallback,
);
const Todos = loadable(
  () => import('apps/TenantApp/screens/Todo'),
  loadingPageFallback,
);
const SetupTenantComplete = loadable(
  () => import('apps/TenantApp/screens/setup/SetupTenantComplete'),
  loadingPageFallback,
);
const SignUpComplete = loadable(
  () => import('apps/TenantApp/screens/setup/SignUpComplete'),
  loadingPageFallback,
);
const SetupTwoFactor = loadable(
  () => import('apps/TenantApp/screens/setup/SetupTwoFactor'),
  loadingPageFallback,
);
const ChangePassword = loadable(
  () => import('apps/TenantApp/screens/authentication/ChangePassword'),
  loadingPageFallback,
);
const SpaceRouter = loadable(
  () => import('./screens/Space'),
  loadingPageFallback,
);
const MemberProfile = loadable(
  () => import('./screens/MemberProfile'),
  loadingPageFallback,
);
const TermsAndConditions = loadable(
  () => import('apps/TenantApp/screens/TermsAndConditions'),
  loadingPageFallback,
);
const VaultsRouter = loadable(
  () => import('./screens/Vaults'),
  loadingPageFallback,
);
const PrivateVault = loadable(
  () => import('screens/PrivateVault'),
  loadingPageFallback,
);
const Calendar = loadable(
  () => import('apps/TenantApp/screens/Calendar'),
  loadingPageFallback,
);
const Favourites = loadable(
  () => import('apps/TenantApp/screens/Favourites'),
  loadingPageFallback,
);
const MySettings = loadable(
  () => import('apps/TenantApp/screens/MySettings'),
  loadingPageFallback,
);
const AccountSwitcher = loadable(
  () => import('apps/TenantApp/screens/AccountSwitcher'),
  loadingPageFallback,
);
const ItemViewer = loadable(
  () => import('apps/TenantApp/screens/ItemViewer'),
  loadingPageFallback,
);
const Chat = loadable(
  () => import('apps/TenantApp/screens/Chat'),
  loadingPageFallback,
);
const Notifications = loadable(
  () => import('./screens/Notifications'),
  loadingPageFallback,
);
const OrgSettings = loadable(
  () => import('apps/TenantApp/screens/OrganisationSettings'),
  loadingPageFallback,
);
const SpaceSettings = loadable(
  () => import('apps/TenantApp/screens/Space/screens/Settings'),
  loadingPageFallback,
);
const SearchModal = loadable(
  () => import('apps/TenantApp/components/SearchModal'),
  loadingPageFallback,
);
const CreateTenant = loadable(
  () => import('screens/CreateTenant'),
  loadingPageFallback,
);
const AnnouncementViewer = loadable(
  () => import('apps/TenantApp/screens/AnnouncementViewer'),
  loadingPageFallback,
);
const I18nTest = loadable(
  () => import('utils/i18n/TestComponent'),
  loadingPageFallback,
);

const UnleashTest = loadable(
  () => import('utils/unleash/TestComponent'),
  loadingPageFallback,
);

const Statistics = loadable(
  () => import('apps/TenantApp/screens/Statistics'),
  loadingPageFallback,
);

const SignInScreen = loadable(
  () => import('apps/TenantApp/screens/authentication/SignIn/index'),
);

const SignInScreenLegacy = loadable(
  () => import('apps/TenantApp/screens/authentication/SignIn/legacy'),
);

const MemberDirectory = loadable(
  () => import('screens/MemberDirectory'),
  loadingPageFallback,
);

SpaceRouter.preload();
ItemViewer.preload();
SearchModal.preload();
AnnouncementViewer.preload();

const TenantAppRoutes = () => {
  const { isGuest } = useSession();
  const useNewLoginFlow = useFlag('new_login');

  return (
    <Routes>
      <Route index element={<IndexRedirect />} />
      <Route
        path={SIGN_IN_PATH}
        element={useNewLoginFlow ? <SignInScreen /> : <SignInScreenLegacy />}
      />
      <Route
        path={SIGN_IN_TWO_FACTOR_PATH}
        element={<Navigate to={defaultTenantLandingPath(isGuest)} />}
      />
      <Route
        path={SIGN_IN_WITH_GLOBAL_TOKEN_PATH}
        element={<SignInFromGlobalEmailToken />}
      />
      <Route
        path={SIGN_IN_WITH_GLOBAL_TOKEN_ACCOUNT_SELECT_PATH}
        element={<SignInWithTokenAccountSelect />}
      />
      <Route element={<SignUpGuard />}>
        <Route path={SIGN_UP_PATH} element={<CreateTenant />} />
      </Route>
      <Route path={RESET_PASSWORD_PATH} element={<ResetPassword />} />
      <Route element={<PublicLayout />}>
        <Route
          path={SIGN_IN_WITH_EMAIL_TOKEN_PATH}
          element={<SignInFromEmail />}
        />
        <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
        <Route
          path={SIGN_IN_WITH_SWITCH_TOKEN_PATH}
          element={<SignInFromSwitch />}
        />
        <Route path={SIGN_UP_CONFIRMATION_PATH} element={<SignUp />} />
        <Route
          path={SETUP_TENANT_CONFIRMATION_PATH}
          element={<SetupTenant />}
        />
        <Route
          path={TERMS_AND_CONDITIONS_PATH}
          element={<TermsAndConditions />}
        />
        <Route path={SETUP_TWO_FACTOR_PATH} element={<SetupTwoFactor />} />
        <Route path={VERIFY_EMAIL_PATH} element={<VerifyEmail />} />
      </Route>

      <Route path="/__test/unleash" element={<UnleashTest />} />
      <Route path="/__test/i18n" element={<I18nTest />} />

      <Route element={<TenantGuard />}>
        <Route element={<AuthenticatedLayout />}>
          <Route path={SIGN_UP_COMPLETE_PATH} element={<SignUpComplete />} />
          <Route path={SETUP_COMPLETE_PATH} element={<SetupTenantComplete />} />
          <Route path={CHANGE_PASSWORD_PATH} element={<ChangePassword />} />

          <Route element={<ApplicationLayout />}>
            {CANONICAL_PREFIXES.map((prefix) => (
              <Route
                key={prefix}
                path={`${prefix}/${pathParam(CANONICAL_ITEM_PARAM)}`}
                element={<CanonicalRedirect prefix={prefix} />}
              />
            ))}
            <Route path={HOME_PATH} element={<Home />} />
            <Route path={START_PATH} element={<Start />} />
            <Route path={NOTIFICATIONS_PATH} element={<Notifications />} />
            <Route path={FAVOURITES_PATH} element={<Favourites />} />
            <Route path={STATISTICS_PATH} element={<Statistics />} />
            <Route path={MEMBER_DIRECTORY_PATH} element={<MemberDirectory />} />

            <Route path={asterisk(SPACES_PATH)} element={<SpaceRouter />} />
            <Route path={asterisk(VAULTS_PATH)} element={<VaultsRouter />} />
            <Route path={PRIVATE_VAULT_PATH} element={<PrivateVault />} />
            <Route path={MORE_PATH} element={<More />} />
            <Route path={TODO_PATH} element={<Todos />} />
            <Route path={MEMBER_PATH} element={<MemberProfile />} />
            <Route path={asterisk(CALENDAR_PATH)} element={<Calendar />} />
            <Route path={asterisk(CHAT_PATH)} element={<Chat />} />
            <Route path="__empty_layout" element={<div>Empty</div>} />
            <Route path="*" element={<NotRecognisedPath />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default TenantAppRoutes;

const PublicLayout = () => <Outlet />;

const AuthenticatedLayout = () => (
  <RequireAuthentication>
    <SessionPulser />
    <SidecarProvider>
      <UnreadNotificationsProvider>
        <UnreadChatMessagesProvider>
          <CalendarViewProvider>
            <FolderViewModeProvider>
              <ItemViewerNavigationProvider>
                <ItemViewer />
                <AnnouncementViewer />
                <MySettings />
                <OrgSettings />
                <SpaceSettings />
                <AccountSwitcher />
                <SearchModal />
                <Outlet />
                <PDFTronPreloader />
              </ItemViewerNavigationProvider>
            </FolderViewModeProvider>
          </CalendarViewProvider>
        </UnreadChatMessagesProvider>
      </UnreadNotificationsProvider>
    </SidecarProvider>
  </RequireAuthentication>
);

const PULSE_INTERVAL = 1000 * 60 * 5;
const PULSE_CHECK_INTERVAL = PULSE_INTERVAL / 5;
const SessionPulser = () => {
  const [lastPulse, setLastPulse] = React.useState<number>(0);
  const [shouldCheckPulse, setShouldCheckPulse] = useFetchKey();

  const doPulse = useCallback(async () => {
    const isActive = Capacitor.isNativePlatform()
      ? (await App.getState()).isActive
      : !document.hidden;

    if (isActive) {
      return await pulse();
    }
  }, []);

  useInterval(setShouldCheckPulse, PULSE_CHECK_INTERVAL);

  useEffect(() => {
    if (lastPulse < Date.now() - PULSE_INTERVAL) {
      doPulse().then((r) => {
        if (r?.status === 200) {
          setLastPulse(shouldCheckPulse);
        }
      });
    }
  }, [shouldCheckPulse, lastPulse]);

  return null;
};

const pulse = () =>
  authenticatedHttpRequest('/pulse', {
    method: 'POST',
  });

const TenantGuard: FC = () => {
  const { tenant } = useSession();

  if (tenant) {
    return <Outlet />;
  } else {
    return <NoTenant />;
  }
};

const SignUpGuard: FC = () => {
  const { tenant } = useSession();

  // @TODO Remove exception on Jan 1st 2023
  // For a short time we will allow "app." based sign up as well.
  // This is so we won't break old links out in the wild.
  const isNewDomain =
    window.location.hostname.startsWith('new.') ||
    window.location.hostname.startsWith('signup.') ||
    window.location.hostname.startsWith('app.');

  if (!tenant && isNewDomain) {
    return <Outlet />;
  } else {
    return (
      <ScreenWithSingleSection>
        <VStack spacing={5}>
          <NotFoundContent />
        </VStack>
      </ScreenWithSingleSection>
    );
  }
};

const IndexRedirect = () => {
  const { tenant, isAuthenticated, isGuest } = useSession();

  if (isAuthenticated) {
    return <Navigate to={defaultTenantLandingPath(isGuest)} />;
  }

  if (tenant || allowNonTenantAuthentication()) {
    return <Navigate to={SIGN_IN_PATH} />;
  }

  return <NoTenant />;
};

const NotRecognisedPath = () => {
  // This happens in the context of an Authenticated tenant.
  return (
    <NotFoundContent>
      <Button as={Link} to={HOME_PATH}>{t`Go home`}</Button>
    </NotFoundContent>
  );
};

const ApplicationLayout = () => {
  const activeLayout = useActiveLayout();
  const Shell = activeLayout === Layout.DESKTOP ? DesktopLayout : MobileLayout;

  return <Shell />;
};

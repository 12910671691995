import {
  Heading,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
} from '@cardboard-ui/react';
import React, { FC } from 'react';
import { t } from '@lingui/macro';
import { AuthenticationScreen } from '../AuthenticationScreen';
import { TrustedFamilyLogo } from './assets/Logo';

export type Tenant = {
  name: string;
  domain: string;
  icon_url: null | string;
  shortcode: string;
};

export type Account = {
  auth_url: string;
  tenant: Tenant;
};

type SigninPickPlatform = {
  onSwitch: (tenant: Account) => void;
  loaded: boolean;
  accounts: Account[] | null;
};
export const SigninPickPlatform = ({
  onSwitch,
  loaded,
  accounts,
}: SigninPickPlatform) => {
  return (
    <AuthenticationScreen>
      <VStack>
        <TrustedFamilyLogo />
        <Heading fontWeight="bold" fontSize="2xl">{t`Welcome back!`}</Heading>
        <Text color="gray.500" fontSize="sm">{t`Choose a platform below`}</Text>
      </VStack>
      {loaded && accounts ? (
        accounts.length ? (
          <VStack justifyContent="flex-start" gap={0}>
            {accounts.map((tenant) => (
              <AuthCard
                key={tenant.tenant.domain}
                tenant={tenant}
                onClick={() => {
                  onSwitch(tenant);
                }}
              />
            ))}
          </VStack>
        ) : null // in this case, we already navigate away
      ) : (
        <Spinner />
      )}
    </AuthenticationScreen>
  );
};

interface AuthCardProps {
  tenant: Account;
  onClick: () => void;
}

const AuthCard: FC<AuthCardProps> = ({ tenant, onClick }) => {
  return (
    <HStack
      as="button"
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
      form={tenant.tenant.domain}
      my={1}
      p={2}
      _hover={{ bg: 'gray.100', _dark: { bg: 'gray.800' } }}
      w="100%"
      rounded="md"
      transition=".1s all"
    >
      <Image
        src={tenant.tenant?.icon_url ?? ''}
        borderWidth="2px"
        borderRadius="md"
        borderColor="white"
        borderStyle="solid"
        alt=""
        boxSize="50px"
        objectFit="cover"
        textAlign="center"
      />
      <VStack align="start" spacing={0}>
        <Heading as="span" size="md">
          {tenant.tenant.name}
        </Heading>
        <Text fontSize="xs">{tenant.tenant.domain}</Text>
      </VStack>
    </HStack>
  );
};

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useInterval, useToast } from '@cardboard-ui/react';
import { useSession } from 'utils/sessionProvider';
import { usePersistedReturnToPath } from 'hooks/useReturnToPath';
import { authenticatedHttpRequest } from 'utils/http';

import useFetchKey from 'hooks/useFetchKey';
import * as Sentry from '@sentry/react';
import { httpSwitchAuthRequest } from '../../SignInFromSwitch';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import { HOME_PATH } from 'utils/routes';
import { useNavigate } from 'react-router-dom';
import isAppDomain from 'utils/isAppDomain';

export type Tenant = {
  name: string;
  domain: string;
  icon_url: null | string;
  shortcode: string;
};

export type Account = {
  auth_url: string;
  tenant: Tenant;
};

const globalizeAuthUrl = (authUrl: string) => {
  if (window.location.hostname.startsWith('app.')) {
    const path = authUrl.split('/').slice(3).join('/');
    return `https://${window.location.hostname}/${path}`;
  } else {
    return authUrl;
  }
};

type LoginScreenWithData = {
  screen: LoginScreen;
  data: {
    token?: string;
    tenantName?: string;
    accounts?: Account[];
  } | null;
};

export const AuthLogicContext = createContext<SignInLogicReturnType>({
  onEmailSignInWithPin: () => {},
  onSubmit: () => {},
  authOptions: [],
  authToken: '',
  resetScreen: () => {},
  openTenant: () => {},
  lastUsedMail: '',
  screen: { screen: 'start', data: null },
  setScreen: () => {},
});

export const useAuthLogic = () => useContext(AuthLogicContext);

export const AuthLogicProvider = ({ children }: { children: ReactNode }) => {
  const isMobileApp = isAppDomain();
  const { isAuthenticated, authenticate } = useSession();
  const [authToken, setAuthToken] = useState<string>();
  const [authTokenFetchKey, resetAuthTokenFetchKey] = useFetchKey();
  const [persistedReturnPath, persistReturnToPath, clearPersistedReturnToPath] =
    usePersistedReturnToPath();
  const toast = useToast();
  const returnToPath = window.location.href.replace(window.location.origin, '');
  const [lastUsedMail, setLastUsedMail] = useState<string>('');
  const [screen, setScreen] = useState<LoginScreenWithData>({
    screen: 'start',
    data: null,
  });
  const navigate = useNavigate();
  const resetScreen = () => {
    setAuthToken(undefined);
  };

  const fetchExternalAuthToken = useCallback(() => {
    authenticatedHttpRequest('/auth/external-auth-token', {
      method: 'POST',
    }).then(async (r) => {
      try {
        const data = await r.json();
        setAuthToken(data.token);
      } catch (e) {
        Sentry.withScope((s) => {
          s.setLevel('warning');
          s.setTag('http_error', 'fetchExternalAuthToken');
          Sentry.captureException(e);
        });
        setTimeout(resetAuthTokenFetchKey, 5000);
      }
    });
  }, [authTokenFetchKey, setAuthToken]);

  useEffect(fetchExternalAuthToken, []);
  useInterval(resetAuthTokenFetchKey, 45 * 60 * 1000);

  const onSubmit = useCallback(
    (
      args:
        | { login: string }
        | { login: string; password: string; tenant_id?: string },
      onCompleted: (
        success: boolean,
        key: string | null,
        statusCode: string | null,
      ) => void,
    ) => {
      if ('password' in args) {
        onPasswordSignIn(args, onCompleted);
      } else {
        onEmailSignIn(args, onCompleted);
      }
    },
    [],
  );

  const onPasswordSignIn = useCallback(
    (
      {
        login,
        password,
        tenant_id,
      }: {
        login: string;
        password: string;
        // only needed for mobile app
        tenant_id?: string;
      },
      onCompleted: (
        success: boolean,
        key: string | null,
        statusCode: string | null,
      ) => void,
    ) => {
      authenticatedHttpRequest('/auth/sign-in', {
        method: 'POST',
        body: JSON.stringify({
          password: password,
          email: login,
          ...(tenant_id ? { tenant_id } : {}),
        }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).then(async (res) => {
        if (res.status == 200) {
          await res.json();
          authenticate();
          onCompleted(true, null, null);
        } else {
          onCompleted(false, null, 'REQUEST_FAILED');
        }
      });
    },
    [],
  );

  const onEmailSignIn = useCallback(
    (
      { login }: { login: string },
      onCompleted: (
        success: boolean,
        key: string | null,
        statusCode: string | null,
      ) => void,
    ) => {
      setLastUsedMail(login);

      persistReturnToPath(returnToPath);

      const authRoute = isMobileApp
        ? '/auth/request-global-email-auth'
        : '/auth/request-email-auth';
      authenticatedHttpRequest(authRoute, {
        method: 'POST',
        body: JSON.stringify({
          email: login,
        }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).then(async (res) => {
        if (res.status == 200) {
          const data = await res.json();
          if (!data.error) {
            onCompleted(true, data.token, null);
          } else {
            onCompleted(false, null, data.status_code);
          }
        } else {
          onCompleted(false, null, 'REQUEST_FAILED');
          clearPersistedReturnToPath();
        }
      });
    },
    [],
  );

  const onEmailSignInWithPin = useCallback(
    (
      {
        pin,
        token,
      }: {
        pin: string;
        token: string;
      },
      onDone: (success: boolean, accounts?: Account[]) => void,
    ) => {
      if (!isAuthenticated) {
        const authRoute = isMobileApp
          ? '/auth/global-email-auth-options'
          : '/auth/email-auth';

        authenticatedHttpRequest(authRoute, {
          method: 'POST',
          body: JSON.stringify({
            key: token,
            pin,
          }),
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        }).then(async (res) => {
          if (res.status == 200) {
            const data = await res.json();
            if (isMobileApp) {
              onDone(true, data?.accounts);
            } else {
              authenticate();
              onDone(true);
            }
            onDone(true, data?.accounts);
          } else {
            onDone(false);
          }
        });
      } else {
        onDone(true);
      }
    },
    [isAuthenticated, authenticate],
  );

  const { resetAppState } = useSession();
  const isApp =
    window.location.hostname.startsWith('app.') ||
    window.location.hostname.startsWith('auth.');

  // taken over from old code
  // TODO We would love to open a new tab here, however we have to make some difficult choices.
  //   The `window.open` technique doesn't reliably work as the `open` event and initial click
  //   are no longer connected to each other. The Browser will kill the pop-up.
  // We could resolve this by requesting the `switchUrl` beforehand, but hen we need to keep refreshing it while the component is visible.
  // We can change this in a 2 step process for the user, where the first step fetches the link, and the other then opens in new tab or not.
  //   This is less nice for the user as they can have to click twice.
  // So for now we just open in the same tab.
  const openTenant = (account: Account, onFail: () => void) => {
    const authUrl = account.auth_url;
    authenticatedHttpRequest(authUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then(async (res) => {
        if (res.status == 200) {
          const responseJson = await res.json();
          const switchUrl = globalizeAuthUrl(responseJson.switch_auth_link);
          const token = switchUrl.split('/').pop();
          if (token) {
            if (isApp) {
              httpSwitchAuthRequest(token).then(async (res) => {
                if (res.status == 200) {
                  /*

                        // We could do a full browser reload, but the native app doesn't like that
                        // window.location.assign(TENANT_DEFAULT_LANDING_PATH

                        // Here we trigger a reload of the session, but that may be problematic
                        // onClose(); // This will ensure we close the modal after the refresh
                        // authenticate();
                      */

                  // Ideally we would just reset the entire application state
                  // onClose(); // This will ensure we close the modal after the refresh
                  if (Capacitor.isNativePlatform()) {
                    await PushNotifications.removeAllListeners();
                    await App.removeAllListeners();
                  }
                  resetAppState();
                  navigate(persistedReturnPath ?? HOME_PATH);
                }
              });
            } else {
              // eslint-disable-next-line no-restricted-properties
              window.location.assign(switchUrl);
            }
          } else {
            onFail();
          }
        } else {
          onFail();
        }
      })
      .catch(() => {
        onFail();
      });
  };

  return (
    <AuthLogicContext.Provider
      value={{
        onEmailSignInWithPin,
        onSubmit,
        authOptions: window.preload_AuthOptions || [],
        authToken,
        resetScreen,
        // fetchAlternateAccounts,
        openTenant,
        lastUsedMail,
        // mapAndSetAccountData,
        screen,
        setScreen,
      }}
    >
      {children}
    </AuthLogicContext.Provider>
  );
};

export type LoginScreen =
  | 'start'
  | 'forgot-platform'
  | 'login-with-password'
  | 'with-global-token'
  | 'with-email-pin'
  | 'pick-tenant'
  | 'find-platform';

export interface SignInLogicReturnType {
  onEmailSignInWithPin(
    {
      pin,
      token,
    }: {
      pin: string;
      token: string;
    },
    onDone: (success: boolean, accounts?: Account[]) => void,
  ): void;
  onSubmit(
    values: any,
    onCompleted: (
      success: boolean,
      token: string | null,
      statusCode: string | null,
    ) => void,
  ): void;
  authOptions: { id: string; url?: string }[];
  authToken: string | undefined;
  resetScreen: () => void;
  openTenant(account: Account, onFail: () => void): void;
  lastUsedMail: string;
  screen: LoginScreenWithData;
  setScreen: (screen: LoginScreenWithData) => void;
}

declare global {
  interface Window {
    reloadAfterAuthClosed: (targetUrl: string) => void;
    preload_AuthOptions?: { id: string; url?: string }[];
  }
}

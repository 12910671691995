import { Link } from '@cardboard-ui/react';
import NotFound from 'apps/TenantApp/screens/NotFound';
import React, { FC } from 'react';
import { generatePath, Navigate, NavigateProps } from 'react-router-dom';
import {
  ANNOUNCEMENT_VIEWER_QUERY_PARAM_NAME,
  CANONICAL_ANNOUNCEMENT_URL_PREFIX,
  CANONICAL_EVENT_URL_PREFIX,
  CANONICAL_ITEM_PARAM,
  CANONICAL_ITEM_URL_PREFIX,
  CANONICAL_MEMBER_URL_PREFIX,
  CANONICAL_PERSON_URL_PREFIX,
  CANONICAL_PREFIXES,
  CANONICAL_PRIVATE_VAULT_ITEM_URL_PREFIX,
  CANONICAL_SPACE_URL_PREFIX,
  CANONICAL_VAULT_ITEM_URL_PREFIX,
  CANONICAL_VAULT_PROFILE_URL_PREFIX,
  CANONICAL_VAULT_URL_PREFIX,
  EVENT_PATH,
  HOME_PATH,
  ITEM_VIEWER_QUERY_PARAM_NAME,
  MEMBER_PATH,
  PRIVATE_VAULT_PATH,
  SPACE_FAMILY_TREE_PATH,
  SPACE_PATH,
  VAULTS_MANAGER_VAULT_PATH,
  VAULTS_MY_VAULT_PATH,
  VAULTS_MY_VAULTS_VAULT_PATH,
  VAULTS_PATH,
} from 'utils/routes';
import useSafeParam from 'utils/routes/useSafeParam';
import useGeneratePath from './useGeneratePath';
import { useLazyLoadRouteInfoQuery } from './useRouteQuery';
import { useLazyLoadVaultInfoQuery } from './useVaultInfoQuery';
import { useLazyLoadVaultItemQuery } from './useVaultItemQuery';
import { useFeatureFlag, useSession } from 'utils/sessionProvider';
import { Mode as MyVaultsMode } from 'screens/MyVaults/components/Provider/context';
import { Mode as PrivateVaultMode } from 'screens/PrivateVault/components/Provider/context';

export const CanonicalRedirect = ({
  prefix,
}: {
  prefix: typeof CANONICAL_PREFIXES[number];
}) => {
  const itemId = useSafeParam(CANONICAL_ITEM_PARAM);

  switch (prefix) {
    case CANONICAL_SPACE_URL_PREFIX:
      return <N to={generatePath(SPACE_PATH, { spaceId: itemId })} />;
    case CANONICAL_MEMBER_URL_PREFIX:
      return <N to={generatePath(MEMBER_PATH, { memberId: itemId })} />;
    case CANONICAL_EVENT_URL_PREFIX:
      return <N to={generatePath(EVENT_PATH, { eventId: itemId })} />;
    case CANONICAL_VAULT_URL_PREFIX:
      return <CanonicalVaultRedirect vaultId={itemId} />;
    case CANONICAL_VAULT_ITEM_URL_PREFIX:
      return <CanonicalVaultItemRedirect vaultItemId={itemId} />;
    case CANONICAL_PRIVATE_VAULT_ITEM_URL_PREFIX:
      return (
        <N
          to={`${PRIVATE_VAULT_PATH}?${new URLSearchParams({
            mode: PrivateVaultMode.HIERARCHICAL,
            i: itemId,
          }).toString()}`}
        />
      );
    case CANONICAL_ANNOUNCEMENT_URL_PREFIX:
      return (
        <N
          to={
            HOME_PATH +
            `?${new URLSearchParams({
              [ANNOUNCEMENT_VIEWER_QUERY_PARAM_NAME]: itemId,
            }).toString()}`
          }
        />
      );
    case CANONICAL_ITEM_URL_PREFIX:
    case CANONICAL_VAULT_PROFILE_URL_PREFIX: // Depricated, and silly name
      return <CanonicalItemRedirect id={itemId} />;
    case CANONICAL_PERSON_URL_PREFIX:
      return <CanonicalPersonRedirect personId={itemId} />;
    default:
      return <Navigate to={HOME_PATH} replace />;
  }
};

const CanonicalItemRedirect = ({ id }: { id: string }) => {
  const data = useLazyLoadRouteInfoQuery({ id });
  const { generateFolderPath, generateFolderItemPath } = useGeneratePath();

  switch (data.node?.__typename) {
    case 'Event':
      // CanonicalItemRedirect must also support Events, as those urls were generated in the past
      return <N to={generatePath(EVENT_PATH, { eventId: data.node.id })} />;
    case 'Folder':
      return <N to={generateFolderPath(data.node)} />;
    case 'FolderItem': {
      if (data.node.ownershipEvent) {
        const params = new URLSearchParams({ i: data.node.id });
        return (
          <N
            to={`${generatePath(EVENT_PATH, {
              eventId: data.node.ownershipEvent.id,
            })}?${params.toString()}`}
          />
        );
      }
      return <N to={generateFolderItemPath(data.node)} />;
    }
    case 'AnnouncementAttachment': {
      const attachmentId = data.node.id;
      return (
        <N
          to={
            HOME_PATH +
            `?${new URLSearchParams({
              [ITEM_VIEWER_QUERY_PARAM_NAME]: attachmentId,
            }).toString()}`
          }
        />
      );
    }
    case 'MemberDetailsAttachment': {
      const attachmentId = data.node.id;
      return (
        <N
          to={
            HOME_PATH +
            `?${new URLSearchParams({
              [ITEM_VIEWER_QUERY_PARAM_NAME]: attachmentId,
            }).toString()}`
          }
        />
      );
    }
  }

  return <NotFound />;
};

const CanonicalPersonRedirect = ({ personId }: { personId: string }) => {
  const { genealogy } = useSession();

  if (genealogy.active) {
    return (
      <N
        to={{
          pathname: generatePath(SPACE_FAMILY_TREE_PATH, {
            spaceId: genealogy.firstSpaceIdWithGenealogy,
          }),
          search: new URLSearchParams({
            memberId: personId,
            selected: personId,
          }).toString(),
        }}
      />
    );
  } else {
    return <NotFound />;
  }
};

const CanonicalVaultRedirect = ({ vaultId }: { vaultId: string }) => {
  const { isMemberOfVault, isVaultManager } = useVaultsInfo();
  const shouldRedirectToHierarchical = useFeatureFlag(
    'FEATURE_ENHANCED_VAULTS_VIEW',
  );

  if (isMemberOfVault(vaultId)) {
    if (shouldRedirectToHierarchical) {
      return (
        <N
          to={`/vaults?${new URLSearchParams({
            mode: 'hierarchical',
            vault: vaultId,
          })}`}
        />
      );
    } else {
      return <N to={generatePath(VAULTS_MY_VAULTS_VAULT_PATH, { vaultId })} />;
    }
  } else if (isVaultManager) {
    return <N to={generatePath(VAULTS_MANAGER_VAULT_PATH, { vaultId })} />;
  } else {
    return <NotFound />;
  }
};

const CanonicalVaultItemRedirect = ({
  vaultItemId,
}: {
  vaultItemId: string;
}) => {
  const shouldRedirectToHierarchical = useFeatureFlag(
    'FEATURE_ENHANCED_VAULTS_VIEW',
  );
  const { isMemberOfVault, isVaultManager } = useVaultsInfo();
  const data = useLazyLoadVaultItemQuery({ vaultItemId });

  if (!data.node || data.node.__typename !== 'VaultItem') {
    return <NotFound />;
  }

  if (isMemberOfVault(data.node.vault.id)) {
    if (shouldRedirectToHierarchical) {
      const vaultId = data.node?.vault?.id;
      return (
        <N
          to={`${VAULTS_PATH}?${new URLSearchParams({
            mode: MyVaultsMode.HIERARCHICAL,
            vault: vaultId,
            i: vaultItemId,
          })}`}
        />
      );
    } else {
      return (
        <N
          to={`${VAULTS_MY_VAULT_PATH}?${new URLSearchParams({
            i: vaultItemId,
          }).toString()}`}
        />
      );
    }
  } else if (isVaultManager) {
    return (
      <N
        to={`${generatePath(VAULTS_MANAGER_VAULT_PATH, {
          vaultId: data.node.vault.id,
        })}?${new URLSearchParams({
          i: vaultItemId,
        }).toString()}`}
      />
    );
  } else {
    return <NotFound />;
  }
};

declare global {
  interface Window {
    waitForCypressRedirect?: boolean;
  }
}

// Not my proudest moment below.
// In order to test the redirect in the e2e tests (redirect.spec.js)
//   we need to slow down the application so that the domain redirect
//   can be asserted.
// In order to do so the tests adds `?confirmRedirect=1` to the url.
//   in returns it expects the redirect logic to provide a redirect link,
//   instead of just doing the redirect.
// It is our responsibility to make sure that those two paths are equivalent.
// If you ever need to fix this, I'm sorry.
const N: FC<{ to: NavigateProps['to'] }> = ({ to }) => {
  if (window.Cypress && window.location.search.includes('confirmRedirect')) {
    return <Link to={to}>Cypress Redirect</Link>;
  } else {
    return <Navigate to={to} replace />;
  }
};

const useVaultsInfo = () => {
  const data = useLazyLoadVaultInfoQuery();
  const vaultIds = data.sessionInfo.member?.vaults?.map((v) => v.id) || [];

  return {
    isMemberOfVault: (vaultId: string) => vaultIds.includes(vaultId),
    isVaultManager: data.sessionInfo.canManageVaults.value,
  };
};

import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
  Button,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  VStack,
} from '@cardboard-ui/react';
import { AuthenticationScreen } from '../AuthenticationScreen';
import { TrustedFamilyLogo } from './assets/Logo';

type FindPlatformProps = {
  onConfirmPlatform: (tenantName: string) => void;
  onForgotPlatform: () => void;
};

export const FindPlatform = ({
  onConfirmPlatform,
  onForgotPlatform,
}: FindPlatformProps) => {
  const [value, setValue] = useState('');

  return (
    <AuthenticationScreen>
      <Stack spacing={4}>
        <VStack
          as="form"
          onSubmit={(event) => {
            event.preventDefault();
            onConfirmPlatform(value);
          }}
        >
          <TrustedFamilyLogo />
          <Heading
            fontWeight="bold"
            fontSize="2xl"
          >{t`Sign in to your platform`}</Heading>
          <Text color="gray.500">
            <Trans>Enter your Platform's Trusted Family URL</Trans>
          </Text>
        </VStack>
        <HStack spacing={0} borderWidth={1} justifyContent="flex-start">
          <Input
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            border="none"
            placeholder={t`your-platform`}
            textAlign="end"
            m={0}
            pe={1}
            autoCapitalize="none"
            style={{
              minWidth: '120px',
              width: `${value.length}ch`,
              maxWidth: 'fit-content',
            }}
          />
          <Text fontWeight="bold">.trustedfamily.com</Text>
        </HStack>

        <Button
          variant="solid"
          colorScheme="blue"
          onClick={() => {
            onConfirmPlatform(value);
          }}
        >{t`Continue`}</Button>

        <HStack
          wrap="wrap"
          justifyContent="center"
          color="gray.500"
          fontSize="sm"
        >
          <Text>{t`Don't know your platform's URL?`}</Text>
          <Button
            onClick={onForgotPlatform}
            variant="link"
            fontWeight="bold"
            textDecoration="underline"
          >{t`Find your platform`}</Button>
        </HStack>
      </Stack>
    </AuthenticationScreen>
  );
};

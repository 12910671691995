import React from 'react';
import { useSession } from 'utils/sessionProvider';
import AuthButton from '../AuthButton';
import SignInTwoFactor from '../SignInTwoFactor';
import { AuthLogicProvider, useAuthLogic } from './utils/SignInLogic';
import {
  SigningInWithPasswordScreenApp,
  SigningInWithPasswordScreenBrowser,
} from './components/SignInWithPasswordLogic';
import { allowNonTenantAuthentication } from './legacy';
import NoTenant from '../../NoTenant';
import AlreadySignedIn from '../AlreadySignedIn';
import SignInTenant from './screens/SignInTenant';
import { SignInViaMailPinScreen } from './screens/SignInViaMailPinScreen';
import SigninPickPlatformScreen from './screens/PickTenant';
import { FindPlatformScreen } from './screens/FindPlatform';
import { ForgotPlatformScreen } from './screens/ForgotPlatform';
import isAppDomain from 'utils/isAppDomain';

const isMobileApp = isAppDomain();

export const GoogleButton = () => {
  const auth = useAuthLogic();
  return (
    <>
      {auth.authOptions?.map(({ url, id }) => (
        <AuthButton
          w="full"
          key={id}
          id={id}
          token={auth.authToken}
          href={url!}
        />
      ))}
    </>
  );
};

const ScreenSwitch = () => {
  const { needsTwoFactor, isAuthenticated } = useSession();
  const {
    screen: { screen },
  } = useAuthLogic();
  const { tenant } = useSession();

  if (!tenant && !allowNonTenantAuthentication()) {
    return <NoTenant />;
  }

  if (isAuthenticated) {
    if (needsTwoFactor) {
      return <SignInTwoFactor />;
    } else {
      return <AlreadySignedIn />;
    }
  }

  switch (screen) {
    case 'find-platform':
      return <FindPlatformScreen />;
    case 'forgot-platform':
      return <ForgotPlatformScreen />;
    case 'login-with-password':
      if (isMobileApp) {
        return <SigningInWithPasswordScreenApp />;
      } else {
        return <SigningInWithPasswordScreenBrowser />;
      }
    case 'with-email-pin':
      return <SignInViaMailPinScreen />;
    case 'pick-tenant':
      return <SigninPickPlatformScreen />;
    default:
      return <SignInTenant />;
  }
};

const SignIn = () => {
  return (
    <AuthLogicProvider>
      <ScreenSwitch />
    </AuthLogicProvider>
  );
};

export default SignIn;

import React from 'react';
import { useAuthLogic } from '../utils/SignInLogic';
import { useToast } from '@cardboard-ui/react';
import { Navigate } from 'react-router-dom';
import { SIGN_IN_PATH } from 'utils/routes';
import { SignInWithEmailTokenRedesigned } from '../../redesigned/SignInWithEmailTokenRedesigned';
import AlreadySignedIn from '../../AlreadySignedIn';
import { t } from '@lingui/macro';
import isAppDomain from 'utils/isAppDomain';

const isMobileApp = isAppDomain();

export const SignInViaMailPinScreen = () => {
  const {
    screen: { data },
    lastUsedMail,
    onEmailSignInWithPin,
    setScreen,
  } = useAuthLogic();

  const toast = useToast();

  if (!lastUsedMail || !data?.token) {
    return <Navigate to={SIGN_IN_PATH} />;
  }

  return (
    <SignInWithEmailTokenRedesigned
      email={lastUsedMail}
      onCodeValidated={(pin: string) => {
        onEmailSignInWithPin(
          { pin, token: data.token as string },
          (success, accounts) => {
            if (success) {
              if (isMobileApp) {
                setScreen({ screen: 'pick-tenant', data: { accounts } });
              } else {
                return <AlreadySignedIn />;
              }
            } else {
              toast({
                title: t`The pin provided is not correct`,
                status: 'error',
                description: t`If this error persists, try requesting the sign-in email again.`,
              });
              // bad case already handled in onEmailSignInWithPin
            }
          },
        );
      }}
    />
  );
};

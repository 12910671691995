import React, { useCallback, useEffect } from 'react';
import { Account, useAuthLogic } from '../utils/SignInLogic';
import { useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { Capacitor } from '@capacitor/core';
import { globalizeAuthUrl } from '../../SignInWithTokenAccountSelect';
import { SigninPickPlatform } from '../../redesigned/SigninPickPlatform';
import { useNavigate } from 'react-router-dom';
import isAppDomain from 'utils/isAppDomain';

const isMobileApp = isAppDomain();

const SigninPickPlatformScreen = () => {
  const navigate = useNavigate();
  const { openTenant, setScreen, screen } = useAuthLogic();
  const loaded = !!screen.data?.accounts;

  const toast = useToast();

  const onFailedSwitch = useCallback(() => {
    toast({
      title: t`Switching to the other organisation failed.`,
      description: t`You can still manually visit the other organisation`,
      status: 'error',
      position: 'top',
      isClosable: true,
    });
  }, [toast]);

  const onSwitch = (tenant: Account) => {
    if (isMobileApp) {
      const url = new URL(tenant.auth_url);
      navigate(url.href.replace(url.origin, ''));
    } else {
      openTenant(tenant, onFailedSwitch);
    }
  };

  useEffect(() => {
    if (screen.data?.accounts && screen.data?.accounts.length === 1) {
      if (Capacitor.isNativePlatform()) {
        const url = new URL(screen.data.accounts[0].auth_url);
        navigate(url.href.replace(url.origin, ''));
      } else {
        // eslint-disable-next-line no-restricted-properties
        window.location.assign(
          globalizeAuthUrl(screen.data.accounts[0].auth_url),
        );
      }
    }
  }, [screen.data?.accounts]);

  return (
    <SigninPickPlatform
      onSwitch={onSwitch}
      accounts={screen.data?.accounts || null}
      loaded={loaded}
    />
  );
};

export default SigninPickPlatformScreen;
